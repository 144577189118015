import React from "react";
import {Link} from '../../../common/link';
import {ContentPage} from "../contentPage";
import {Pages} from '../../../config/pages';

import * as styles from './elnoksegPage.module.scss';

const option = (name, loc) =>  <Link className={styles.option} location={loc} >
    <p className={styles.optionText}>{name}</p>
</Link>;

export class ElnoksegPage extends React.Component {
	


    render() {
  
        return (
            <ContentPage activePage={-1}>
            <div className={styles.container}>
                {option("Események", Pages.elnokseg.posts)}
                {option("Dokumentumok", Pages.elnokseg.files)}
            </div>
            

            </ContentPage>
        );
    }
}

